.chat-container {
  width: 100%;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-image: url('https://media.istockphoto.com/id/1371726643/photo/different-notifications-on-violet-background-pop-up-messages-copy-space.jpg?b=1&s=170667a&w=0&k=20&c=FhmBYUrsGsR0hYn5zEDa0SkEFfF-rwuUfMEZ4HpX0rE=');
  background-repeat: no-repeat;
  background-size: cover;
}
.chat-content {
  max-width: 450px;
  margin-top: 66px;
  margin-left: 19px;
}
.chatMenu1 {
  text-align: left;

  margin: 2%;
}
/* .rcw-picker-btn:disabled{
    height: 40px !important;
    background-color: #fff;
    
  } */
.MuiTypography-body2,
.MuiListItemText-secondary,
.css-83ijpv-MuiTypography-root {
  text-align: right;
}
.css-1hskriy {
  width: 100% !important;
}
.disabled {
  display: contents;
}
/* .rcw-picker-icon,.rcw-send-icon{
    display:contents 

  } */
.hidden:disabled {
  display: contents !important;
}

.chat-header {
  display: flex;
  padding: 12px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  background-color: #007bff;
  color: white;
}
.chat_header {
  margin-left: 200px;
}
.chat_list {
  font-size: 17px;
  cursor: pointer;
}
.chat_list_cont {
  position: absolute;
  background-color: #ccc;
  width: 30%;
  height: 87%;
}
.chat_list_cont div {
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 1px;
  border-bottom: 2px solid white;
  font-weight: bold;
}

.chat-messages {
  height: 300px;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.message {
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
}

.user-message {
  display: inline-block !important;
  /* background-color: #e2f2ff;*/
  background-color: #e2f2ff;
  margin-left: auto;
  max-width: 50% !important;
  width: auto !important;
  padding: 5px;
}
.msg_bg {
  display: inline;

  border-radius: 5px;
  word-break: break-all;
}
.admin-message {
  margin-right: auto;
  max-width: 50% !important;
  width: auto !important;
  background-color: #46ffe6;
  padding: 5px;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 8px;
  border-top: 1px solid #ccc;
}

.chat-input input {
  flex: 1;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 5px !important;
}

.chat_input_modal {
  flex: 1;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 5px !important;
}

.chat-input input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}
.chat_input_modal:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.chat-input button {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #0056b3;
}
.modal_button {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-left: 10px !important;
}
.modal_button:hover {
  background-color: #0056b3;
}

/* .rcw-conversation-container > .rcw-sender {
    display: none ;
  } */
/* body {
    font-family: 'Saira Semi Condensed', sans-serif;
    background: #ccc;
  padding:30px;
} */
.popup-link {
  display: flex;
  flex-wrap: wrap;
}

.popup-link a {
  background: #333;
  color: #fff;
  padding: 10px 30px;
  border-radius: 5px;
  font-size: 17px;
  cursor: pointer;
  margin: 20px;
  text-decoration: none;
}

.popup-container {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(1.3);
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(21, 17, 17, 0.61);
  display: flex;
  align-items: center;
}
.popup-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  border-radius: 6px;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: relative;
}
.popup_btns_commaon {
  /* background-color: #0c6fdf !important; */
  color: #000 !important;
  font-size: 14px !important;
  padding: 5px 10px !important;
  margin-top: 10px !important;
  text-transform: capitalize !important;
  /* width: 100%; */
}
.other_issue_popup_plus_btn_inner {
  display: grid;
  grid: 50px / auto auto;
  grid-column-gap: 10px;
  margin-top: 70px;
}
.popup_btns_commaon:hover {
  color: #060202 !important;
}
.popup-content p {
  font-size: 17px;
  padding: 10px;
  line-height: 20px;
}
.popup-content a.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  background: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  position: absolute;
  right: 10px;
  top: 10px;
}

.popup-content a.close:hover {
  color: #d91717 !important;
}

.popup-content span:hover,
.popup-content span:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.popup-container:target {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.popup-container h3 {
  margin: 10px;
}
/*End popup styles*/

/* Additional styles */

/* .popup-style-7{
  transform: skewY(180deg);
   transition: all 0.7s ease-in-out;
}

.popup-style-7:target{
 transform: skewY(0deg);

 } */
/* */
.other_issue_popup_plus_btn_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.other_issue_popup_plus_btn_1,
.other_issue_popup_plus_btn_2,
.other_issue_popup_plus_btn {
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 10px;
}
.rcw-close-widget-container{
  display: none !important;
}
/* .rcw-messages-container{
    height: 40vh !important;
  } */
.rcw-conversation-container .rcw-title {
  padding: 0 !important;
}
.please_wait_text {
  font-size: 16px;
  width: 80%;
  margin: auto;
  margin-top: 10px;
  color: #ff0000;
  margin-bottom: 10px;
  /* background-color: red ; */
  position: absolute;
  /* z-index: 9999999999; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.othermenu_list {
  /* background-color: #35cce6; */
  background-color: #003441;
  position: absolute;
  padding: 0px 15px;
  top: 50% !important;
  left: 50%;
  border-radius: 15px;
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: none;
  height: auto;
  width: 90%;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: #fff !important;
  /* width: 50% !important; */
  line-height: none !important;
}
.hidebtn {
  position: absolute;
  right: 1rem;
  top: 10px;
}

.loaderReturn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgb(255, 255, 255);
}
.loader-overlay {
  background-image: url('https://bkd.khiladiludo.in/public/icon/1684930333690-1-867297072.webp');
}
.chat_btn {
  text-align: center;
  justify-content: space-between;
  align-items: center;
  font-size: initial;
  color: #ffffff;
  font-weight: 800;
}

.rcw-launcher {
  background: transparent !important;
  box-shadow: none !important;
  width: 100px !important;
}
.rcw-hide-sm {
  background-color: #35cce6 !important;
  box-shadow: 0 2px 10px 1px #b5b5b5 !important;
  width: 60px !important;
}
.rcw-open-launcher {
  width: 100px !important;
}
.rcw-conversation-container .rcw-header,
.rcw-conversation-container .rcw-close-button {
  background-color: #003441 !important;
}
.rcw-client .rcw-message-text {
  background-color: #ffbdbd !important;
}
.rcw-message-text p {
  color: #000;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem !important;
}
@media screen and (max-width: 800px) {
  .other_issue_popup_plus_btn {
    position: relative !important;
    right: 45px !important;
  }
  .chat_btn {
    font-size: 12px;
    font-weight: 600;
  }
  .rcw-widget-container {
    top: 20px !important;
    /* width: 99% !important; */
  }
  .rcw-conversation-container {
    height: 85% !important;
  }
  .rcw-launcher {
    bottom: 70px !important;
    right: 15px !important;
  }
  .rcw-conversation-container .rcw-close {
    width: 14px !important;
    height: 14px !important;
  }
  .rcw-conversation-container .rcw-close-button {
    right: 5px !important;
  }
  .other_issue_popup_plus_btn {
    right: 30px !important;
  }
  .other_issue_popup_plus_btn_1,
  .other_issue_popup_plus_btn_2,
  .other_issue_popup_plus_btn {
    width: 32px !important;
    height: 32px !important;
  }
  .othermenu_list {
    top: 250% !important;
  }
}
.whatsap_btn{
  position: fixed;
  right: 20px;
  bottom: 30px;
  z-index: 9999999999999;
  /* background: red; */
  min-height: auto !important
}

@media screen and (max-width: 800px) {
  .whatsap_btn{
    right: 20px;
  bottom: 40px;
  }
}